import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { isLoggedIn, sessionExpired } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            if(sessionExpired) {
                dispatch(openSnackbar({
                    open: true,
                    message: "Session Expired",
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        severity:"error"
                    },
                    close: false,
                }))
            }
            navigate('login', { replace: true });
        }
    }, [dispatch, isLoggedIn, navigate, sessionExpired]);

    return children;
};

export default AuthGuard;
