
// material-ui

// assets
import { ReactComponent as MedScanenrLogo } from 'assets/images/ms-logo.svg';

// project imports

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <MedScanenrLogo width={180} height={50} />
);

export default LogoSection;
