// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import userReducer from './slices/user';
import menuReducer from './slices/menu';
import api from "./api";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    [api.reducerPath]: api.reducer,
    snackbar: snackbarReducer,
    user: persistReducer(
        {
            key: 'user',
            storage,
            keyPrefix: 'ms-'
        },
        userReducer
    ),
    menu: menuReducer
});

export default reducer;
export type IRootReducer = typeof reducer;
export type IRootState = ReturnType<typeof reducer>;