import { NavItemType } from 'types';
import { FormattedMessage } from 'react-intl';
// assets
import { DashboardOutlined, PeopleOutlineOutlined, InfoOutlined, ExitToAppOutlined, ListOutlined } from '@mui/icons-material';
// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'user-management',
            title: <FormattedMessage id="user-management" />,
            type: 'item',
            url: '/user-management',
            icon: PeopleOutlineOutlined,
            breadcrumbs: false
        },
        {
            id: 'medicine-management',
            title: <FormattedMessage id="medicine-management" />,
            type: 'item',
            url: '/medicine-management',
            icon: InfoOutlined,
            breadcrumbs: false
        },
        {
            id: 'display-options',
            title: <FormattedMessage id="display-options" />,
            type: 'item',
            url: '/display-options',
            icon: ListOutlined,
            breadcrumbs: false
        },
        {
            id: 'logout',
            title: <FormattedMessage id="logout" />,
            type: 'item',
            url: '/',
            icon: ExitToAppOutlined,
            breadcrumbs: false
        },
    ]
};

export default menuItems;
