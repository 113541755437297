import * as React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Typography
} from '@mui/material';

import { DialogProps } from '@mui/material/Dialog';
import { FormattedMessage } from 'react-intl';

// ===============================|| SELECTION ROW ||=============================== //

interface ConfirmationDialogRawProps extends DialogProps {
    _onClose: (v?: string) => void;
    _onOk: () => void;
    value: string;
    valueProp?: string;
    open: boolean;
    confirmationText?: string
}

export default function ConfirmationDialogRaw({ _onClose, _onOk, title, value: valueProp, open, confirmationText = "done", ...other }: ConfirmationDialogRawProps) {
    const theme = useTheme();
    const [value, setValue] = React.useState(valueProp);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleOk = () => {
        _onOk();
        _onClose();
    }

    const handleCancel = () => {
        _onClose();
    };

    return (
        <Dialog aria-labelledby="confirmation-dialog-title" open={open} {...other}>
            {open && (
                <>
                    {title && <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle> }
                    <DialogContent dividers>
                        <Typography>
                          {value}
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
                        <Button sx={{ color: theme.palette.error.dark }} autoFocus color="secondary" onClick={handleCancel}>
                            <FormattedMessage id="cancel"/>
                        </Button>
                        <Button variant="contained" size="small" onClick={handleOk}>
                            <FormattedMessage id={confirmationText}/>
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
