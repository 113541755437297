import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { FIREBASE_API } from 'config';
import { FirebaseContextType, InitialLoginContextProps } from 'types/auth';

import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

let fbApp;
let fbAnalytics;
let fbDb;

// firebase initialize
if (!firebase.apps.length) {
	fbApp = firebase.initializeApp(FIREBASE_API);
	fbAnalytics = getAnalytics(fbApp);
	fbDb = getFirestore(fbApp);
}

// const
const initialState: InitialLoginContextProps = {
	isLoggedIn: false,
	isInitialized: false,
	user: null,
	sessionExpired: false,
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const firebaseApp = fbApp;
export const analytics = fbAnalytics;
export const db = fbDb;

export const FirebaseProvider = ({ children }: { children: React.ReactElement }) => {
	const [state, dispatch] = useReducer(accountReducer, initialState);

	useEffect(
		() =>
			firebase.auth().onAuthStateChanged((user) => {
				let sessionTimeout = null;
				if (user) {
					// Fetch the decoded ID token and create a session timeout which signs the user out.
					user.getIdTokenResult().then((idTokenResult) => {
						// Make sure all the times are in milliseconds!
						const authTime = idTokenResult.claims.auth_time * 1000;
						const sessionDuration =  60 * 60 * 1000; // 1 hour
						const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
						sessionTimeout = setTimeout(() => {
							dispatch({
								type: LOGOUT,
								payload: {
									isLoggedIn: false,
									user: null,
									sessionExpired: true
								}
							});
							return firebase.auth().signOut();
						}, millisecondsUntilExpiration);
					})
					// User is logged in.
					dispatch({
						type: LOGIN,
						payload: {
							isLoggedIn: true,
							user: {
								id: user.uid,
								email: user.email!,
							},
							sessionExpired: false
						}
					});
				} else {
					// User is logged out.
					// Clear the session timeout.
					sessionTimeout && clearTimeout(sessionTimeout);
					sessionTimeout = null;
					dispatch({
						type: LOGOUT,
						payload: {
							isLoggedIn: false,
							user: null,
							sessionExpired: false
						}
					});
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	);

	const firebaseEmailPasswordSignIn = (email: string, password: string) => firebase.auth().signInWithEmailAndPassword(email, password);

	const firebaseGoogleSignIn = () => {
		const provider = new firebase.auth.GoogleAuthProvider();

		return firebase.auth().signInWithPopup(provider);
	};

	const firebaseRegister = async (email: string, password: string) => firebase.auth().createUserWithEmailAndPassword(email, password);

	const logout = () => firebase.auth().signOut();

	const resetPassword = async (email: string) => {
		await firebase.auth().sendPasswordResetEmail(email);
	};

	const reAuthenticateUser = async (password: string) => {
		const user = await firebase.auth().currentUser;

		if (!user) {
			throw new Error("User not found");
		}

		const credentials = await firebase.auth.EmailAuthProvider.credential(
			// @ts-ignore
			user.email,
			password
		)

		return user.reauthenticateWithCredential(credentials);
	}

	const updateProfile = async (email: string, password: string): Promise<void> => {
		const user = await firebase.auth().currentUser;

		if (!user) {
			throw new Error("User not found");
		}
		try {
			await reAuthenticateUser(password);

			await user.updateEmail(email)
		} catch (err: any) {
			throw new Error(err.message)
		}
	}

	const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
		const user = await firebase.auth().currentUser;

		if (!user) {
			throw new Error("User not found");
		}
		try {
			await reAuthenticateUser(oldPassword);

			await user.updatePassword(newPassword)
		} catch (err: any) {
			throw new Error(err.message)
		}
	}

	if (state.isInitialized !== undefined && !state.isInitialized) {
		return <Loader />;
	}

	return (
		<FirebaseContext.Provider
			value={{
				...state,
				firebaseRegister,
				firebaseEmailPasswordSignIn,
				login: () => { },
				firebaseGoogleSignIn,
				logout,
				resetPassword,
				updateProfile,
				changePassword
			}}
		>
			{children}
		</FirebaseContext.Provider>
	);
};

export default FirebaseContext;
