import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const Medicines = Loadable(lazy(() => import('views/pages/medicines')));
const DisplayOptions = Loadable(lazy(() => import('views/pages/display-options')));
const Users = Loadable(lazy(() => import('views/pages/user-management')));
const AccountProfile = Loadable(lazy(() => import('views/application/users/account-profile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        }, {
            path: '/user-management',
            element: <Users />
        }, {
            path: '/medicine-management',
            element: <Medicines />
        }, {
            path: '/display-options',
            element: <DisplayOptions />
        }, {
            path: '/user/profile',
            element: <AccountProfile />
        }, {
            path: '/*',
            element: <Dashboard />
        },
    ]
};

export default MainRoutes;
